<script setup lang="ts">
  import JiraFeedback from '~/components/modules/JiraFeedback.vue'

  const route = useRoute()

  const mainElement = ref<HTMLElement | null>(null)
  const { arrivedState } = useScroll(mainElement)
  const { width } = useWindowSize()

  const navigationStore = useNavigationStore()
</script>

<template>
  <div>
    <TheHeader
      :environment="route.meta.environment ?? 'portal'"
      :class="{ 'shadow-md': !arrivedState.top }"
      class="sticky top-0 z-30 transition-shadow"
    />
    <div class="flex flex-row">
      <TheNavigation :routes="navigationStore.items ?? []" />
      <main
        ref="mainElement"
        class="grow flex flex-row overflow-auto h-[calc(100vh-5rem)]"
        :class="[width < 1280 ? 'ml-14' : '', $slots.utility ? 'mr-64' : '']"
      >
        <div class="p-12 h-fit w-full">
          <slot />
        </div>
        <div
          v-if="$slots.utility"
          class="bg-[#F5F5F5] border-l fixed right-0 bottom-0 top-20 overflow-y-auto"
        >
          <div class="block w-64 h-full flex flex-1 flex-col justify-between">
            <slot name="utility" />
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<style scoped></style>
