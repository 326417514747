<script setup lang="ts">
  const props = defineProps<{
    item: {
      name: string
      icon?: string
      path: string
      children?: any[]
      isClickable?: boolean
      auth?: string[]
    }
    iconOnly?: boolean
    selected?: boolean
    hasParent?: boolean
  }>()

  const route = useRoute()
  const { t } = useI18n()

  const isActive = (path: string) => {
    return route.path.startsWith(path)
  }

  const emits = defineEmits(['clickArrow'])

  const authStore = useAuthStore()
</script>

<template>
  <NuxtLink
    v-if="authStore.isInRoles(item.auth?.roles ?? [])"
    :to="props.item.isClickable ? props.item.path : '#'"
  >
    <li
      class="m-2 px-2 py-3 rounded text-base flex gap-2"
      :class="{
        'bg-primary text-white': isActive(props.item.path),
        'hover:bg-gray-100 hover:text-black': !isActive(props.item.path),
        'bg-gray-100 text-black': props.selected,
        'font-bold': !props.hasParent,
        'cursor-pointer': props.item.isClickable,
      }"
    >
      <img
        :src="`/icons/${props.item.icon}-24px-${isActive(props.item.path) ? 'white' : 'black'}.svg`"
        class="w-6 h-6"
        v-if="props.item.icon"
      />
      <div
        class="transition-opacity duration-300 whitespace-nowrap flex-grow text-ellipsis overflow-hidden ... text-nowrap"
        :class="iconOnly ? 'opacity-0 invisible' : 'opacity-100'"
      >
        {{ t(`nav.menu.${props.item.name}`) }}
      </div>
      <img
        v-if="props.item.children"
        @click="emits('clickArrow')"
        class="transition-opacity cursor-pointer"
        :class="iconOnly ? 'opacity-0 invisible' : 'opacity-100'"
        :src="`/icons/chevron-right-24px-${isActive(props.item.path) ? 'white' : 'black'}.svg`"
        width="24"
        height="24"
      />
    </li>
  </NuxtLink>
</template>

<style scoped></style>
