<script setup lang="ts">
  // Quick fix to load script; when using useHead and switching layouts it will not load the script anymore
  onMounted(() => {
    let divScripts = document.getElementById('load-script')
    let newScript = document.createElement('script')
    newScript.src =
      'https://xuntos.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-vg1gsr/b/8/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=eef0ad55'
    divScripts.appendChild(newScript)
  })

  window.ATL_JQ_PAGE_PROPS = {
    triggerFunction: (showCollectorDialog) => {
      document.querySelectorAll('.jira-feedback-button').forEach((e) => {
        e.onclick = (e) => {
          e.preventDefault()
          showCollectorDialog()
        }
      })
    },
  }
</script>

<template>
  <div id="load-script"></div>
  <BasicBtn
    driver-id="feedback-button"
    class="jira-feedback-button flex-shrink-0 bg-[#6ab7e7] text-white hover:bg-[#6ab7e7]/80 hover:text-white/80 active:bg-[#6ab7e7]/60 active:text-white/60 focus:text-white/60"
    color="textual-borderless"
    size="regular"
  >
    <template #prepend-icon>
      <Icon name="ic:outline-chat" class="size-5" />
    </template>
    Feedback
  </BasicBtn>
</template>

<style scoped>
  #load-script {
    display: none;
  }
</style>
